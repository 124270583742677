import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import { Provider, useSelector, useDispatch } from "react-redux"
import ReactModal from "react-modal"
import { toast } from "react-toastify"

import store from "./store"
import App, { router } from "./app"

import Modal from "./components/Modal"
import ModalContext from "./modal"
import "./index.css"
import { ToastContainer, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

ReactModal.setAppElement("#root")
;(() => {
  let lastActivity = Date.now()

  const setActivity = () => {
    lastActivity = Date.now()
  }

  document.addEventListener("input", setActivity)
  document.addEventListener("mousedown", setActivity)

  const hour = 1000 * 60 * 60
  setInterval(() => {
    if (
      Date.now() - lastActivity > hour &&
      sessionStorage.getItem("sap_token")
    ) {
      toast.info("Your session has timed out. Please log in again.")
      router.navigate("/logout")
    }
  }, 1000)
})()

function ModalProvider({ children }) {
  const [content, setContent] = useState(null)
  const setModal = (content) => setContent(content ? content : null)
  return (
    <ModalContext.Provider value={setModal}>
      <Modal isOpen={!!content} onRequestClose={() => setModal()}>
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  )
}

function ToastWrapper({ children }) {
  return (
    <>
      <div style={{ flex: "auto", display: "flex", flexDirection: "column" }}>
        {children}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <ModalProvider>
      <ToastWrapper>
        <Provider store={store}>
          <App />
        </Provider>
      </ToastWrapper>
    </ModalProvider>
  </React.StrictMode>
)
