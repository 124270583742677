import { useState } from "react"
import styled from "styled-components"
// import { colors } from "./styles"

function _Base({ className, children, onClick = () => {}, ...rest }) {
  return (
    <button className={className} onClick={() => onClick()} {...rest}>
      {children}
    </button>
  )
}

const Base = styled(_Base)`
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`

const UnstyledButton = styled(Base)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
`

const Primary = styled(UnstyledButton)`
  width: min(100%, 300px);
  background: black;
  padding: 16px 32px;
  color: white;
  font-weight: bold;
  border-radius: 28px;

  &[disabled] {
    color: rgb(66, 66, 66);
    background: rgb(179, 178, 185);
  }
`

const PrimaryOutline = styled(Primary)`
  background-color: transparent;
  border: 2px solid black;
  border-width: 1px;
  color: black;
`

const Secondary = styled(UnstyledButton)`
  color: black;
  font-weight: 600;
`

const Button = Primary

export function LoadingButton({ onClick, children, ...rest }) {
  const [loading, setLoading] = useState(false)

  const unlock = () => setLoading(false)
  return (
    <Button
      disabled={loading}
      onClick={() => {
        setLoading(true)
        onClick(unlock)
      }}
      {...rest}
    >
      {loading ? "Loading..." : children}
    </Button>
  )
}

export { UnstyledButton, Button, Primary, PrimaryOutline, Secondary }
