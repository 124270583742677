import { useState } from "react"
import TextInput from "../components/TextInput"
import { LoadingButton } from "../components/Button"
import { sapClient } from "../client"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams, Link } from "react-router-dom"
import { toast } from "react-toastify"

function Card({ children }) {
  const style = {
    padding: "48px 64px",
    boxShadow: "0 0 8px 0 rgba(0,0,0,0.1)",
  }
  return <div style={style}>{children}</div>
}

function Layout({ title, children }) {
  return (
    <div
      className="page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "300px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: "32px",
              fontWeight: "bold",
            }}
          >
            <span>{title}</span>
          </div>
          {children}
        </div>
      </Card>
    </div>
  )
}

export default function Page() {
  const [searchParams, _] = useSearchParams()

  const forgot = searchParams.get("forgot")
  const reset = searchParams.get("reset")

  console.log(sapClient)

  if (forgot) {
    return <ForgotPassword />
  } else if (reset) {
    return <ResetPassword token={reset} />
  } else {
    return <Login />
  }
}

export function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <Layout title="SYSTEM ADMINISTRATION">
      <label htmlFor="email" style={{ display: "block" }}>
        email:
      </label>
      <TextInput id="email" value={email} onChange={(v) => setEmail(v)} />
      <label htmlFor="password" style={{ display: "block" }}>
        password:
      </label>
      <TextInput
        id="password"
        type="password"
        value={password}
        onChange={(v) => setPassword(v)}
      />
      <Link to="/?forgot=1">Forgot Password?</Link>
      <LoadingButton
        disabled={!email || !password}
        onClick={async (unlock) => {
          try {
            const resp = await sapClient.login({ email, password })
            const { token, permissions } = resp
            sessionStorage.setItem(
              "sap_token",
              JSON.stringify({ token, permissions })
            )
            dispatch({ type: "auth/login_success", token, permissions })
            navigate("/dashboard/participants", { replace: true })
          } catch (e) {
            toast.error("an error occurred")
            console.error(e)
          } finally {
            unlock()
          }
        }}
      >
        submit
      </LoadingButton>
    </Layout>
  )
}

export function ForgotPassword() {
  const [email, setEmail] = useState("")
  return (
    <Layout title="FORGOT PASSWORD">
      <p>
        Enter your email address and a link will be sent to reset your password:
      </p>
      <label htmlFor="email" style={{ display: "block" }}>
        email:
      </label>
      <TextInput id="email" value={email} onChange={(v) => setEmail(v)} />
      <LoadingButton
        disabled={!email}
        onClick={async (unlock) => {
          try {
            await sapClient.reSendPasswordResetEmail({ email })
            toast.success("email sent")
          } catch (e) {
            toast.error("an error occurred")
            console.error(e)
          } finally {
            unlock()
          }
        }}
      >
        submit
      </LoadingButton>
      <Link to="/">Go back to login page</Link>
    </Layout>
  )
}

export function ResetPassword({ token }) {
  const [password, setPassword] = useState("")

  const [err, setErr] = useState("")

  return (
    <Layout title="RESET PASSWORD">
      {token ? (
        <>
          <label htmlFor="password" style={{ display: "block" }}>
            New password:
          </label>
          <TextInput
            id="password"
            type="password"
            value={password}
            onChange={(v) => setPassword(v)}
          />
          {err && (
            <div
              style={{ color: "red", whiteSpace: "pre-line", fontSize: "12px" }}
            >
              {err}
            </div>
          )}
          <LoadingButton
            disabled={!password}
            onClick={async (unlock) => {
              try {
                await sapClient.resetAccountPassword({ key: token, password })
                toast.success("password reset successfully")
                setPassword("")
                setErr("")
              } catch (e) {
                if (e.meta.reason) {
                  setErr(e.meta.reason)
                } else {
                  toast.error("an error occurred")
                  console.error(e)
                }
              } finally {
                unlock()
              }
            }}
          >
            submit
          </LoadingButton>
          <Link to="/">Go back to login page</Link>
        </>
      ) : (
        <div>missing token</div>
      )}
    </Layout>
  )
}
