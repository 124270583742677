import { combineReducers } from 'redux'

const defaultAuthState = {token: '', authed: false, permissions: []}
function authReducer(state = defaultAuthState, action) {
  switch (action.type) {
    case 'auth/login_success':
      const { token, permissions } = action
      return {...state, token, permissions, authed: true}
    case 'auth/logout_success':
      return defaultAuthState
    default:
      return state
  }
}

const rootReducer = combineReducers({
  auth: authReducer
})

export default rootReducer
