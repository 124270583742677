import styled from "styled-components"
import { colors } from "./styles"

const Input = styled.input`
  height: 48px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid rgba(39, 39, 39, 0.16);
  transition: box-shadow 150ms ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem ${colors.bluebird};
  }
`

export default function TextInput({ id, label, type = "text", onChange = () => {}, style, ...rest }) {
  return (
    <div style={{ display: "inline-block", ...style }}>
      {label && (
        <label htmlFor={id} style={{ display: "block", marginBottom: "16px" }}>
          {label}
        </label>
      )}
      <Input id={id} type={type} onChange={(e) => onChange(e.target.value)} {...rest} />
    </div>
  )
}
