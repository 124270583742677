import { useEffect, useMemo } from "react";
import { useTable, useSortBy, useRowState } from "react-table";
import { Outlet } from "react-router-dom";
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;

  tbody tr:nth-child(odd){
    background: gainsboro;
  }
`

const downarrow = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYzLjEgKDkyNDUyKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT51aS9hcnJvdy1zZWxlY3QtZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSIvdWkvYXJyb3ctc2VsZWN0LWRvd24iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik01LjM0NTcxMzc1LDkgTDE4LjY1NDI4NjMsOSBDMTkuODUwOTM5NCw5IDIwLjQ0OTI2NiwxMC41NTA0MzQgMTkuNjA0ODk4NiwxMS40NTQzNTUgTDEyLjk1MDYxMjMsMTguNTc3OTcwNyBDMTIuNDI0OTc5NiwxOS4xNDA2NzY0IDExLjU3NTAyMDQsMTkuMTQwNjc2NCAxMS4wNTQ5Nzk1LDE4LjU3Nzk3MDcgTDQuMzk1MTAxNDMsMTEuNDU0MzU1IEMzLjU1MDczNDAxLDEwLjU1MDQzNCA0LjE0OTA2MDU5LDkgNS4zNDU3MTM3NSw5IFoiIGlkPSJpY24tYXJyb3ctdXAtY29weS0yIiBmaWxsPSIjNTQ1NDU0IiBmaWxsLXJ1bGU9Im5vbnplcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyLjAwMDAwMCwgMTQuMDAwMDAwKSByb3RhdGUoLTM2MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTIuMDAwMDAwLCAtMTQuMDAwMDAwKSAiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+"

function UpArrow() {
  const style = {
    transform: "scaleY(-1) translateY(-2px)",
  }
  return (
    <img style={style} height="12" src={downarrow}/>
  )
}

function DownArrow() {
  return (
    <img height="12" src={downarrow}/>
  )
}


export default function Table({columns, data}) {

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, autoResetRowState: false, autoResetSortBy: false }, useSortBy, useRowState);

  return (
    <Container>
    <table {...getTableProps()} style={{ border: "solid 1px blue" }} border="0" cellPadding="0" cellSpacing="0">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  background: "silver",
                  color: "black",
                  fontWeight: "bold",
                  padding: "10px",
                  width: column.width,
                  textAlign: "left",
                }}
              >
                {column.render("Header")}
              <span style={{minWidth: 24}}>
                {column.isSorted
                  ? column.isSortedDesc
                    ? <DownArrow/>
                    : <UpArrow/>
                  : ''
                }
              </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    </Container>
  );
}
