export default function RadioList({ name, options, style, value, onChange }) {
  return (
    <div>
      {options.map((o, i) => (
        <div key={i}>
          <input
            name={name}
            type="radio"
            id={o.value}
            value={o.value}
            checked={value === o.value}
            disabled={o.disabled ?? false}
            onChange={(e) => onChange(e.target.value)}
          />
          <label htmlFor={o.value}>{o.label}</label>
        </div>
      ))}
    </div>
  )
}
