import { useEffect, useMemo, useState, useRef } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Table from "../components/Table"
import Nav from "../components/Nav"
import Modal from "../components/Modal"
import { sapClient } from "../client"
import Toggle from "../components/Toggle"
import "react-toggle/style.css"
import { toast } from "react-toastify"
import Protected from "../components/Protected"
import { Primary, PrimaryOutline, Secondary } from "../components/Button"
import TextInput from "../components/TextInput"
import OptionsSelect from "../components/OptionsSelect"

export function Deactivated() {
  const style = {
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "rgb(220, 53, 69)",
  }
  return <div style={style}></div>
}

const roleNames = {
  studyStaff: "Study Staff",
  studyStaffLeads: "Study Staff Lead",
  clinicians: "Clinician",
  clinicianLeads: "Clinician Lead",
  admins: "Admins",
  centralAssessors: "Central Assessor",
}

export function Label({ label, children }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "0 16px 16px 0" }}>
      <div>
        {" "}
        <label>{label}</label>
      </div>
      <div> {children} </div>
    </div>
  )
}

function Row({ children, ...rest }) {
  return <div style={{ display: "flex", flexDirection: "row", ...rest }}>{children}</div>
}

function roleOptions(permissions) {
  const opts = [{ value: "", disabled: true }]

  for (const perm of permissions) {
    const [a, b, c] = perm.split(".")
    if (a != "users" || c != "edit") {
      continue
    }

    opts.push({
      value: b,
      label: roleNames[b],
    })
  }

  return opts
}

function AccountModal({ onCancel, onSave, onResendPasswordEmail, header, selectedAccount = { role: "" }, isEditing }) {
  const { permissions } = useSelector((x) => x.auth)
  const [account, setAccount] = useState(selectedAccount)

  const isChanged = account.active !== selectedAccount.active
  const isCompleted = account.firstName && account.lastName && account.email && account.role

  return (
    <div>
      <h2>{header}</h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "32px", marginTop: "48px" }}>
        <OptionsSelect
          id="role"
          disabled={isEditing}
          question="Role:"
          variant="primary"
          value={account.role}
          options={roleOptions(permissions).map(({ value, label, disabled }) => ({ value, text: label, disabled }))}
          onChange={(v) => setAccount({ ...account, role: v })}
        />

        <Row gap="16px">
          <TextInput
            id="firstname"
            label="First Name:"
            disabled={isEditing}
            type="text"
            value={account.firstName}
            onChange={(v) => setAccount({ ...account, firstName: v })}
            style={{ flex: 1 }}
          />
          <TextInput
            id="lastname"
            label="Last Name:"
            disabled={isEditing}
            type="text"
            value={account.lastName}
            onChange={(v) => setAccount({ ...account, lastName: v })}
            style={{ flex: 1 }}
          />
        </Row>

        <Row gap="16px">
          <TextInput
            id="email"
            label="Email:"
            disabled={isEditing}
            type="text"
            value={account.email}
            onChange={(v) => setAccount({ ...account, email: v })}
            style={{ flex: 1 }}
          />

          {isEditing && (
            <Toggle
              id="active"
              label="Active:"
              checked={account.active}
              onChange={(e) => setAccount((account) => ({ ...account, active: e.target.checked }))}
            />
          )}
        </Row>

        <Protected needs={["admins.accounts.sendPasswordReset"]}>
          {isEditing && (
            <Secondary onClick={() => onResendPasswordEmail(account.email)}>Send Password Reset Email</Secondary>
          )}
        </Protected>
      </div>

      <div style={{ display: "flex", justifyContent: "center", gap: "32px", marginTop: "48px" }}>
        <PrimaryOutline onClick={onCancel}>Cancel</PrimaryOutline>
        <Primary disabled={!isCompleted || (isEditing && !isChanged)} onClick={() => onSave(account)}>
          Save
        </Primary>
      </div>
    </div>
  )
}

export default function Accounts() {
  const [accounts, setAccounts] = useState([])
  const { permissions } = useSelector((x) => x.auth)
  const [showModal, setShowModal] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState()

  useEffect(() => {
    sapClient.listSapAccounts().then((res) => setAccounts(res.sapAccounts))
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "active",
        width: 20,
        Cell: ({ value }) => <>{!value ? <Deactivated /> : null}</>,
      },
      {
        Header: "First Name",
        accessor: "firstName",
        width: 120,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        width: 120,
      },
      {
        Header: "Email Address",
        accessor: "email",
        width: 120,
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => roleNames[value],
        width: 150,
      },
      {
        Header: "Action",
        disableSortBy: true,
        Cell: (cell) => (
          <Secondary
            children="Edit"
            onClick={() => {
              setSelectedAccount(cell.value)
              setShowModal(true)
            }}
          />
        ),
        accessor: "userId",
        width: 120,
      },
    ],
    []
  )

  const createAccount = async (account) => {
    try {
      const { userId, active } = await sapClient.createSapAccount(account)
      setAccounts([...accounts, { ...account, userId, active }])
      toast.success("Account created")
      setShowModal(false)
    } catch (e) {
      toast.error("an error occurred")
      console.error(e)
    }
  }

  const updateAccount = async (account) => {
    try {
      await sapClient.updateSapAccount(account)
      const rest = accounts.filter((x) => x.userId != account.userId)
      setAccounts([...rest, account])
      toast.success("Account updated")
      setShowModal(false)
    } catch (e) {
      toast.error("an error occurred")
      console.error(e)
    }
  }

  const sendPasswordResetEmail = async (email) => {
    if (!email) {
      toast.error("an error occurred")
      console.error("missing email")
      return
    }

    try {
      await sapClient.reSendPasswordResetEmail({ email })
      toast.success("email sent")
    } catch (e) {
      toast.error("an error occurred")
      console.error(e)
    }
  }

  const account = accounts.find((x) => x.userId === selectedAccount)
  const header = account ? "Update Account" : "Add Account"
  const onSave = account ? updateAccount : createAccount

  return (
    <div className="page">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h1>Accounts</h1>
        <PrimaryOutline
          style={{ width: "initial" }}
          onClick={() => {
            setSelectedAccount()
            setShowModal(true)
          }}
        >
          Add Account
        </PrimaryOutline>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Deactivated /> - Represents a deactivated account
      </div>
      <Table columns={columns} data={accounts} />
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <AccountModal
          header={header}
          onCancel={() => setShowModal(false)}
          onSave={onSave}
          onResendPasswordEmail={sendPasswordResetEmail}
          selectedAccount={account}
          isEditing={!!selectedAccount}
        />
      </Modal>
    </div>
  )
}
