import store from "../store"
import { DefaultStudyAdminPortalService } from "./service"

function fetchWithToken(req) {
  const { auth } = store.getState()
  if (auth.token) {
    req.headers.append("Authorization", auth.token)
  }
  return fetch(req)
}

const httpClient = {
  downloadCsv(data) {
    const r = new Request("/api/sap/download-csv", {
      method: "POST",
      body: JSON.stringify(data),
    })
    return fetchWithToken(r)
  },
  exportAssessments(data) {
    const r = new Request("/api/sap/export-assessments", {
      method: "POST",
      body: JSON.stringify(data),
    })
    return fetchWithToken(r)
  },
}

export const sapClient = new DefaultStudyAdminPortalService("", fetchWithToken)
export { httpClient }
