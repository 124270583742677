import React, { useEffect, useState } from "react"
import {
  Outlet,
  BrowserRouter,
  RouterProvider,
  Routes,
  Route,
  Navigate,
  useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { Provider, useSelector, useDispatch } from "react-redux"

import Dashboard from "./pages/Dashboard"
import Login from "./pages/Login"
import E404 from "./pages/E404"
import DownloadRecords from "./pages/DownloadRecords"
import Playground from "./pages/Playground"
import Accounts from "./pages/Accounts"
import Participants from "./pages/Participants"
import Sites from "./pages/Sites"
import Studies from "./pages/Studies"
import MyPassword from "./pages/MyPassword"

function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    sessionStorage.removeItem("sap_token")
    dispatch({ type: "auth/logout_success" })
    navigate("/")
  }, [])

  return null
}

function Guard({ children }) {
  const authed = useSelector((state) => state.auth.authed)
  if (!authed) return <Navigate to="/" replace={true} />

  return children
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route index element={<Login />} />
      <Route
        path="/dashboard/"
        element={
          <Guard>
            <Dashboard />
          </Guard>
        }
      >
        <Route path="participants" element={<Participants />} />
        <Route path="records" element={<DownloadRecords />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="studies" element={<Studies />} />
        <Route path="sites" element={<Sites />} />
        <Route path="my-password" element={<MyPassword />} />
      </Route>
      <Route path="logout" element={<Logout />} />
      <Route path="playground" element={<Playground />} />
      <Route path="*" element={<E404 />} />
    </>
  )
)

export default function App() {
  return <RouterProvider router={router} />
}
