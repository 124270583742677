import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'

let preloadedState = {}
const st = sessionStorage.getItem('sap_token')
if (st) {
  const {permissions, token} = JSON.parse(st)
  preloadedState = {
    auth: {token, permissions, authed: true}
  }
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
})

export default store
