import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHasPermissions } from "../components/Protected"
import { Outlet } from "react-router-dom"
import { Link, NavLink } from "react-router-dom"

import { sapClient } from "../client"
import Nav from "../components/Nav"

function PortalTitle() {
  const style = {
    color: "white",
    fontSize: "16px",
    lineHeight: "24px",
  }
  return (
    <div style={style}>
      STUDY
      <br />
      ADMINISTRATION
      <br />
      PORTAL
    </div>
  )
}

function hasPermissions(needs, has) {
  if (!needs) {
    return true
  }

  for (const need of needs) {
    if (has.indexOf(need) >= 0) {
      return true
    }
  }

  return false
}

function NavMenu() {
  const hasPermissions = useHasPermissions()

  const style = {
    display: "flex",
    flexDirection: "column",
  }

  const links = [
    { to: "/dashboard/participants", text: "Participants" },
    {
      to: "/dashboard/accounts",
      text: "Accounts",
      every: false,
      permissions: [
        "users.centralAssessors.edit",
        "users.studyStaffLeads.edit",
        "users.studyStaff.edit",
        "users.clinicianLeads.edit",
        "users.clinicians.edit",
      ],
    },
    {
      to: "/dashboard/records",
      text: "Records",
      every: false,
      permissions: ["assessments.participantRecords.generateCsv", "assessments.clinicianRecords.generateCsv"],
    },
    { to: "/dashboard/studies", text: "Studies", permissions: ["admins.sites.edit"] },
    { to: "/dashboard/sites", text: "Sites", permissions: ["admins.sites.edit"] },
    { to: "/dashboard/my-password", text: "My Password" },
  ]

  const visibleLinks = links.filter((x) => hasPermissions(x.permissions, x.every ?? true))

  return (
    <div style={style}>
      {visibleLinks.map((link, i) => (
        <NavLink
          key={i}
          to={link.to}
          style={({ isActive }) => {
            const style = {
              color: "white",
              textDecoration: "none",
              padding: "16px 32px",
              borderLeft: "4px solid rgb(84, 84, 84)",
            }
            if (isActive) {
              return {
                ...style,
                background: "rgb(101, 101, 101)",
                borderLeft: "4px solid white",
              }
            }
            return style
          }}
        >
          {link.text}
        </NavLink>
      ))}
    </div>
  )
}

function HeaderBar() {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    color: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    minHeight: "56px",
    padding: "0 64px",
    alignItems: "center",
  }

  const linkStyle = {
    textDecoration: "none",
    fontWeight: "bold",
    color: "rgb(101, 101, 101)",
  }
  return (
    <div style={style}>
      {/* <img src={logo} alt="" style={{position: "absolute", left: "50%", transform: "translateX(-50%)", height: "80%"}} /> */}
      <Link to="/logout" style={linkStyle}>
        Logout
      </Link>
    </div>
  )
}

export default function Dashboard() {
  // useEffect(() => {
  //   ;(async () => {
  //     const resp = await sapClient.example({})
  //     console.log(resp)
  //   })()
  // }, [])

  return (
    <div className="page" style={{ display: "flex" }}>
      <div style={{ flex: 1, background: "rgb(84, 84, 84)" }}>
        <div style={{ margin: "32px 20px" }}>
          <PortalTitle />
        </div>
        <NavMenu />
      </div>
      <div style={{ flex: 5 }}>
        <HeaderBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "32px",
          }}
        >
          <div style={{ width: "min(100%, 800px)" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
